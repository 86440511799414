@use '~/styles/modules' as *;

.overrideBtn {
  background: rgba(32, 32, 32, 0.25);
  border: 4px solid blue;
}

.Navbar {
  background-color: transparent;
  width: 100%;
  height: 4rem;
  margin: 0 0 1.6rem 0;
  @include flexContainer(row, flex-start, center);
  font: $p1;
  max-width: 1312px;

  .navbarMenu {
    z-index: 1;
    &.signIn {
      margin-left: auto;
    }
  }

  .dropdownButton {
    width: 2rem;
  }

  .chevronButton {
    position: absolute;
    right: -0.6rem;
    top: 01rem;
    height: 2rem;
    width: 2rem;

    @include small-screens() {
      right: -0.5rem;
    }

    transition: 2s ease-in-out;
  }

  @include tablet-screens {
    .left {
      margin-left: auto;
    }
  }

  .button {
    @include flexContainer(row, center, center);
    height: 4rem;
    line-height: 24px;
    min-width: 40px;
    padding: 1rem;
    border-radius: 50%;
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.2);
    margin: 0 0 0 8px;

    .gradient {
      @include textGradient();
    }

    &:hover:not(.avatar),
    &:focus-visible {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)),
        rgba(32, 32, 32, 0.5);

      p {
        @include textGradient();
      }
    }

    &.gradient {
      @include textGradient();
    }
  }

  @include desktop-screens() {
    .button {
      border: none;
    }
  }

  .label {
    display: none;
    color: var(--theme-grey6);
    font-weight: 600;

    &.selected {
      position: relative;
      color: #ffffff;
      font-weight: 700;

      &::after {
        content: '';
        position: absolute;
        bottom: -0.4rem;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 2px;
        border-radius: 12px;
        background: linear-gradient(to right, #fa9579, #ffffff);
      }
    }

    @include small-screens() {
      display: block;
    }
  }

  .icon {
    width: 16px;
    height: 16px;
    margin: 0 auto;
  }

  .divider {
    width: 1px;
    height: 24px;
    background-color: var(--background3);
    margin: 0 12px;
  }

  .navLinks {
    z-index: 1;
    @include flexContainer(row, space-between, center);
    color: var(--text-color);
    margin-left: auto;

    .buttonLink {
      line-height: 24px;
      padding: 8px;
      margin-left: 12px;
      border-radius: 12px;
      @include flexContainer(row, center, center);
      transition: all 2s;

      &:hover,
      &:focus-visible {
        background-color: rgba(255, 255, 255, 0.05);

        span {
          @include textGradient();
        }
      }

      &:active {
        background-color: rgba(255, 255, 255, 0.2);

        span {
          @include textGradient();
        }
      }
    }

    li {
      &:first-child {
        margin-left: calc(32px - 12px);
      }
      &.iconNavMenu {
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 50%;
        margin-right: 0.8rem;
        a {
          border-radius: 50%;
        }
      }
      a {
        border-radius: 50%;
        line-height: 24px;
        margin-inline: 4px;
        padding: 8px;
        border-radius: 1.2rem;
        min-width: 40px;
        min-height: 40px;
        @include flexContainer(row, center, center);
        font-weight: 600;
        // background: rgba(32, 32, 32, 0.25);

        &:hover,
        &:focus-visible {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)),
            rgba(32, 32, 32, 0.5);
          // border-radius: 51%;

          span {
            @include textGradient();
            transition: all 0.35s;
          }
        }

        > svg {
          &:hover {
            border-radius: 52%;
          }
        }

        &:active {
          background: rgba(32, 32, 32, 0.75);

          span {
            @include textGradient();
          }
        }
      }

      .rewardsButton {
        margin-inline: 0px;
      }
    }
  }
}
